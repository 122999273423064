<script>
    export default{
        props:{
            profileImage:{
                type: String,
                default: null
            },
            ossImage:{
                type: String,
                default: null
            },
            initials: {
                type: String,
                default: null
            }
        }
    }
</script>

<template>
    <div class="flex justify-end">
        <div class="flex pr-2">
            <div v-if="profileImage">
                <img
                    class="inline-block h-12 w-12 rounded-full"
                    :src="'/storage/profile_images/' + profileImage">
            </div>
            <div v-else-if="ossImage && ossImage.includes('on-site-selfie')">
                <img
                    class="inline-block h-12 w-12 rounded-full"
                    :src="ossImage.replace('public', 'storage')">
            </div>
            <div v-else class="flex justify-center">
                <span
                    class="inline-flex items-center justify-center h-12 w-12 rounded-full bg-gray-400">
                    <span
                        class="text-xl font-medium leading-none text-white">{{ initials }}</span>
                </span>
            </div>
        </div>
    </div>
</template>

<style scoped>

</style>
